
export default {
    data() {
        return {
            config: {
                host: process.env.VUE_APP_HOST_DOMAIN,
                apiUrl: process.env.VUE_APP_HOST_DOMAIN + process.env.VUE_APP_BASE_API,
                widgetBaseUrl: process.env.VUE_APP_WIDGET_BASE_URL,
                widgetCssBaseUrl: process.env.VUE_APP_WIDGET_CSS_BASE_URL,
                ssoClientId: process.env.VUE_APP_SSO_HOST,
                ssoHost: process.env.VUE_APP_SSO_HOST,
                gameServerHost: process.env.VUE_APP_GAME_SERVER_HOST,
                opapWidgetAccountDomain: process.env.VUE_APP_OPAP_WIDGET_ACCOUNT_DOMAIN,
                googleOptimizerId: process.env.VUE_APP_GOOGLE_OPTIMIZER_ID,
                xmasVillageUrl: process.env.VUE_APP_XMAS_VILLAGE_URL
            }
        }
    }
}
